
import './navbarhome.css';

import React, { useState,useEffect } from 'react';

import {login, getProfilePicUrl, getUserName, isUserSignedIn} from './firebase_login';

import {connect} from 'react-redux';

// import {flask_multipart} from './flask-s3-multipart';
// import './flask_multipart.css';

import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import Signin from './signin';

import { Link } from "gatsby";

function Navblog(props) {

    useEffect(() => {
      // const origin_ = window.location.origin 

      // document.getElementById('href1').href = window.location.origin

      // flask_multipart(props, document.getElementById('file-caption2'))

 document.getElementById('login_blog').addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();


            document.getElementById('signx').style.display = 'block'

});

      console.log('props in add_captions_to_insta_video', props)
  },)
	return (
		<div>


		<nav className="navbar navbar-inverse navbar-fixed-top home-fixed-top" role="navigation">
    <div className="container">
    <Signin props={props} />

        <div className="navbar-header">

            <div className="navbar-brand">
                <Link className="CB" to="/">TurtleClip</Link> 
            </div>

            <div id="google_translate_element"></div>
        

     
            <div className="home-list">
              
<div id="login_blog" className="signup3">Try Now, Free</div>
 
            </div>

    </div>
    </div>
</nav>


		</div>

		)
}


const mapStateToProps = (state) => {
  console.log('!!!mapStateToProps', state)
  return {
  boxes2: state.boxes2,
  canvasValid: state.canvasValid,
  selectText: state.selectText,

  xout: state.xout,
  width: state.width,
  height: state.height,
  pc: state.pc,
  back_bar: state.back_bar, 
  front_bar: state.front_bar,
  bar_height: state.bar_height,
  duration: state.duration,
  dvid: state.dvid,
  pre_sub: state.pre_sub,
  ti: state.ti,

  render: state.render,
  subscribe: state.subscribe,
  uid: state.uid,
  email: state.email,
  pic: state.pic,
  filename: state.filename,
  
}}

const mapDispatchToProps = dispatch => {
  return {
    
    increment: (box) => dispatch({ type: 'UPDATE_BOXES', payload: box}),
    update_x : (index, x, fill) => dispatch({ type: 'UPDATE_X', payload: {'index':index,'value':fill, 'x':x} }),
    edit_boxes : (index, value) => dispatch({ type: 'EDIT_BOXES', payload: {'index':index,'value':value} }),
    pop_boxes : (index) => dispatch({ type: 'POP_BOXES', payload: {'index':index} }),
    invalidate : (value) => dispatch({ type: 'INVALIDATE', payload: value}),
    selectedText : (value) => dispatch({ type: 'SELECTED_TEXT_BOX', payload: value}),
    render_x : (x, value) => dispatch({ type: 'RENDER_X', payload: {'x':x, 'value': value}}),
    decrement: () => dispatch({ type: 'DECREMENT' }),
    reset: () => dispatch({ type: 'RESET' })
  }
}


Navblog.displayName = "Navblog";
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Navblog));
