import React from "react"
import { graphql } from "gatsby"

import { useEffect } from 'react';

import '../components/add_captions_to_insta_video.css'

import Layout from "../components/layout"
import SEO from "../components/seo"


import {Link} from "gatsby"

import Navblog from '../components/navblog';


// import {flask_multipart} from '../components/flask-s3-multipart';
// import '../components/flask_multipart.css';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

// import defaultOpenGraphImage from './pop2.webp'
import Signin from '../components/signin';


export default function BlogPost({ data }) {
// export default BlogPost( props ) {

  const post = data.markdownRemark


    useEffect(() => {
      // flask_multipart(post, document.getElementById('file-caption'))

    document.getElementById('login3').addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();


            document.getElementById('signx').style.display = 'block'

});


    document.getElementById('login4').addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();


            document.getElementById('signx').style.display = 'block'

});
    

},)

  // <div className="up-try" style={{'width': 'auto', 'margin':'4rem'}}>
  //     <div className="file-chooser">
  //       <label for="file-caption" style={{'font-size': '1.2rem', 'border-radius': '10rem'}} className="custom-file-upload">
  //           upload video <ArrowRightAltIcon />
  //       </label>
  //       <input id="file-caption" style={{'display': 'none'}} type="file" accept="video/mp4,video/mov,video/m4v" />
  //     </div>

  //     <div className="file-progress-container"></div>
  //     <script src="md5.js"></script>
  //     <script src="flask-s3-multipart.js"></script>
  //   </div>
 

// render() {

  const siteTitle = data.site.siteMetadata.title
  const { ogimage } = post.frontmatter
  const ogImagePath = ogimage && ogimage.childImageSharp.fixed.src

      // <small>{post.frontmatter.date}</small>
  return (
    <div>

      <SEO 
          title={post.frontmatter.title}
          description={post.excerpt}
          type="blog"
          image={ogImagePath}
      />
      <Navblog props={post} />
      <Signin props={post} />
      <h1 style={{'marginTop': '12vw'}}>{post.frontmatter.title}</h1>
      <h4>{post.frontmatter.summary}</h4>


 



      <div id="login3" className="signup3">Get Started for Free</div>


      <div dangerouslySetInnerHTML={{ __html: post.html }} />


      <div id="login4" className="signup3">Start Free Trial Now</div>

      <footer className='foot-blog'>
        © {new Date().getFullYear()}
        {` `}
      <Link to="/">TurtleClip</Link>
      </footer>
      <div className='footerx'>
      <Link to="/add-captions-to-insta-video">add captions to video</Link>
      <br />
      <Link to="/translate-zoom-video">translate video</Link>
      <br />
      <Link to="/how-to-subtitle-in-arabic">Subtitle in Arabic</Link>
      <br />
      <Link to="/easy-transcribe-spanish-audio">Subtitle in Spanish</Link>
      <br />

      </div>

    </div>
  )
}



export const query = graphql`
  query BlogQuery($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }

    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date
        summary

    ogimage { 
      childImageSharp {
        fixed {
          src
        }
      }
    }

      }
    }
  }
`