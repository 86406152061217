import { types } from "./types";

export const increment = (box) => (dispatch) => {
      dispatch({
        type: types.UPDATE_BOXES,
        payload: box,
      });
};


export const invalidate = (value) => (dispatch) => {
	dispatch({ type: types.INVALIDATE, 
 	payload: value, 
 });
};


export const update_x = (index, x, fill) => (dispatch) => {
	dispatch({ type: types.UPDATE_X, 
	payload: {'index':index,'value':fill, 'x':x} 
  });
};
    
export const edit_boxes = (index, value) => (dispatch) => {
	dispatch({ type: types.EDIT_BOXES, 
	payload: {'index':index,'value':value} 
	});
};

export const pop_boxes = (index) => (dispatch) => {
	dispatch({ type: types.POP_BOXES, 
	payload: {'index':index} 
	});
};

export const selectedText = (value) => (dispatch) => {
	dispatch({ type: types.SELECTED_TEXT_BOX, 
	payload: value 
	});
};

export const render_x = (x, value) => (dispatch) => {
	dispatch({ type: types.RENDER_X, 
	payload: {'x':x, 'value': value}
	});
};

export const decrement = () => (dispatch) => {dispatch({ type: 'DECREMENT' });
};

export const reset = () => (dispatch) => {dispatch({ type: 'RESET' });};