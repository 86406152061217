import firebase from "@firebase/app";

import 'firebase/auth';
// import '@firebase/firestore';
// import '@firebase/functions';
import 'firebase/analytics';

const config = {
  apiKey: process.env.GATSBY_REACT_APP_FIREBASE_KEY,
  authDomain: process.env.GATSBY_REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.GATSBY_REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.GATSBY_REACT_APP_FIREBASE_PROJECT_ID,
  // projectId: "turtleclip",
  storageBucket: process.env.GATSBY_REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_REACT_APP_FIREBASE_SENDER_ID,
  appId: "1:126988606543:web:0c87f871649f80da3c9e3a",
  measurementId: "G-8EYQRL3EVQ",
};

let instance;

export default function getFirebase() {
  if (typeof window !== 'undefined') {
    if (instance) return instance;
    firebase.initializeApp(config);
    firebase.analytics();
    
    instance = firebase;
    

    
    return instance;
  }

  return null;
}

