import React, { useEffect } from 'react';
import './signin.css';
// import useFirebase from './useFirebase';
import getFirebase from './firebase'; // import our getFirebase function
import {login, passwordlessLogin} from './firebase_login';

import ClearIcon from '@material-ui/icons/Clear';
import CircularProgress from '@material-ui/core/CircularProgress';


import {connect} from 'react-redux';
import { increment, update_x, edit_boxes, pop_boxes, invalidate, selectedText, render_x, decrement, reset } from "../actions/sampleAction";



const firebase = getFirebase();


function closeWidget() {
	document.getElementById('signx').style.display = 'none'

}

function Signin(props){	


    console.log('props in signin', props)

    useEffect(() => {

        document.getElementById('signin').addEventListener('click', (e) => {

        if (!firebase) return; 

        e.preventDefault();
        e.stopPropagation();

    document.getElementById("circleSign").style.display = 'block';
        login(e, props, firebase)
});


        document.getElementById('pformxsign').addEventListener('submit', (e) => {

    e.preventDefault();
    e.stopPropagation();

    document.getElementById("circleSign").style.display = 'block';

    let email = document.getElementById("pemailtrxsign").value
    passwordlessLogin(e, email, firebase)

});

        
        document.getElementById('clearsignIn').addEventListener('click', (e) => {

    e.preventDefault();
    e.stopPropagation();

    closeWidget()
        })

})


return (
	<div id="signx">

<ClearIcon id='clearsignIn' />

<CircularProgress id="circleSign"  />


<button className='signin' id='signin' type="button"><div><svg width="18" height="18" xmlns="http://www.w3.org/2000/svg"><g fill="#000" fill-rule="evenodd"><path d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z" fill="#EA4335"></path><path d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z" fill="#4285F4"></path><path d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z" fill="#FBBC05"></path><path d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z" fill="#34A853"></path><path fill="none" d="M0 0h18v18H0z"></path></g></svg></div><span className='signgoogle'>Log in with Google</span></button>

<br />
Or
<br />

  <form id='pformxsign'>
    <input type='email' id="pemailtrxsign" placeholder="Enter Email" required oninvalid="this.setCustomValidity('Please Enter valid email')"/>

    <input id='psubmitx' type='submit' value='Get Magic Link' />
  </form>

	</div>



)}




const mapStateToProps = (state) => {
  console.log('!!!mapStateToProps', state)
  return {
  boxes2: state.data.boxes2,
  canvasValid: state.data.canvasValid,
  selectText: state.data.selectText,

  xout: state.data.xout,
  width: state.data.width,
  height: state.data.height,
  pc: state.data.pc,
  back_bar: state.data.back_bar, 
  front_bar: state.data.front_bar,
  bar_height: state.data.bar_height,
  duration: state.data.duration,
  dvid: state.data.dvid,
  pre_sub: state.data.pre_sub,
  ti: state.data.ti,

  render: state.data.render,
  subscribe: state.data.subscribe,
  uid: state.data.uid,
  email: state.data.email,
  pic: state.data.pic,
  filename: state.data.filename,
  
}}


Signin.displayName = "Signin";
export default connect(mapStateToProps, { increment, update_x, edit_boxes, pop_boxes, invalidate, selectedText, render_x, decrement, reset })(React.memo(Signin));



